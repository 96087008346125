.footer {
  margin: auto 0 0 0;
  padding: 79px 70px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgba(32, 32, 32, 1);
  min-height: 172px;
}

.footer__title {
  margin: 0;
  padding: 0 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(66, 66, 66, 1);

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgba(139, 139, 139, 1);
  text-align: center;
}

.footer__content {
  margin: 0;
  padding: 20px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer__copyrighting {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer__links {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  list-style: none;
}

.footer__link {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-decoration: none;
  cursor: pointer;

  transition: opacity 0.5s ease-in;
}

.footer__link:hover {
  opacity: 0.7;
}

@media (max-width: 1023px) {
  .footer {
    padding: 79px 30px 20px;
  }
}

@media (max-width: 600px) {
  .footer {
    padding: 79px 10px 20px;
  }
  .footer__title {
    padding: 0 0 21px;
    font-size: 12px;
  }
  .footer__content {
    flex-direction: column-reverse;
    padding: 30px 0 0;
    gap: 30px;
  }
  .footer__links {
    flex-direction: column;
    gap: 12px;
  }
  .footer__link {
    font-size: 12px;
  }
  .footer__copyrighting {
    font-size: 12px;
  }
}
