@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url(./inter-400-regular.woff2) format("woff2"),
       url(./inter-400-regular.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url(./inter-500-medium.woff2) format("woff2"),
       url(./inter-500-medium.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url(./inter-900-black.woff2) format("woff2"),
       url(./inter-900-black.woff) format("woff");
}
