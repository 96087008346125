.profile {
  margin: 0;
  padding: 74px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #202020;
  flex: 1;
}

.profile__container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 410px;
}

.profile__title {
  margin: 0 0 123px;
  padding: 0;
  box-sizing: border-box;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.profile__info-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.profile__info-container::after {
  content: attr(data-error);
  position: absolute;
  text-align: center;
  width: 80%;
  top: 110%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: rgba(238, 52, 101, 1);
}

.profile__info {
  margin: 0 0 17px;
  padding: 0 0 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(66, 66, 66, 1);
}

.profile__info_editable {
  position: relative;
  padding: 0;
  min-height: 30px;
  border-bottom: none;
}

.profile__info:nth-of-type(2) {
  margin: 0;
  border: none;
}

.profile__name {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile__name_editable {
  padding: 3px;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 5px;
  border: 1px solid gray;
  z-index: 1;
}

.profile__value {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile__input {
  margin: 0;
  padding: 10px 65px 10px 5px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  border: none;
  outline: none;
  background-color: transparent;
  flex: 1;
  width: 100%;

  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-align: left;
  border-bottom: 1px solid rgba(66, 66, 66, 1);

  transition: all 0.3s ease;
}

.profile__input:focus {
  border-bottom: 1px solid rgba(66, 133, 244, 1);
}

.profile__input:invalid {
  border-bottom: 1px solid rgb(196, 30, 80);
}

.profile__btn-container {
  margin: 220px 0 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.profile__btn {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;

  transition: opacity 0.3s ease-in;
}

.profile__btn_type_exit {
  font-weight: 500;
  color: rgba(238, 52, 101, 1);
}

.profile__save-button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  min-height: 45px;
  border-radius: 3px;
  background: #4285f4;
  outline: none;
  border: none;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
}

.profile__save-button_type_cancel {
  color: #000;
  background: rgb(167, 159, 159);
}

.profile__save-button_disabled {
  pointer-events: none;
  cursor: not-allowed;
  background: #5c687a;
}

.profile__btn:hover {
  opacity: 0.7;
}

.profile__save-button:hover {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .profile {
    padding: 236px 0 0;
  }
  .profile__title {
    margin: 0 0 96px;
  }
  .profile__btn-container {
    margin: 210px 0 0;
  }
}

@media (max-width: 500px) {
  .profile {
    padding: 70px 30px 0;
  }
  .profile__info-container::after {
    font-size: 10px;
  }
  .profile__container {
    width: 100%;
  }
  .profile__title {
    margin: 0 0 80px;
  }
  .profile__btn-container {
    margin: 379px 0 0;
  }
}
