.techs-card {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 60px;
  border-radius: 10px;
  background: #303030;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.56px;
}

@media (max-width: 1023px) {
  .techs-card {
    width: 84px;
    height: 57px;
    font-size: 12px;
    letter-spacing: -0.48px;
  }
}
