.account-button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 13px;
  cursor: pointer;

  transition: opacity 0.3s ease;
}

.account-button_place_mobile {
  margin: auto 0 0;
}

.account-button__name {
  margin: 0;
  padding: 8px 0;
  box-sizing: border-box;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}

.account-button__image {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  border-radius: 6px;
  background: #0f4157;
}

.account-button:hover {
  opacity: 0.7;
}

.account-button__image_place_main {
  background: rgba(49, 49, 49, 1);
}

@media (max-width: 768px) {
  .account-button {
    display: none;
  }
  .account-button_place_mobile {
    display: flex;
  }
  .account-button__image {
    background: rgba(49, 49, 49, 1);
  }
}
