.movies-card {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;

  background: rgba(34, 34, 34, 1);
  height: 319px;
  overflow: hidden;
}

.movies-card:hover {
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
}

.movies-card__content {
  margin: 0;
  padding: 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.movies-card__name {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: #fff;
  max-width: 240px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.movies-card__time {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: rgba(139, 139, 139, 1);
  min-width: 70px;
  text-align: right;
}

.movies-card__photo {
  max-width: 100%;
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center center;

  transition: all 0.3s ease;
}

.movies-card__photo:hover {
  transform: scale(1.02);
}

.movies-card__btn {
  margin: 18px 0 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 30px;
  background-color: rgba(49, 49, 49, 1);
  width: 100px;
  min-height: 29px;
  cursor: pointer;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
  color: #fff;

  transition: opacity 0.3s ease;
}

.movies-card__btn_disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.movies-card__btn:hover {
  opacity: 0.7;
}

@media (max-width: 1023px) {
  .movies-card {
    height: 309px;
  }
  .movies-card__btn {
    margin: 18px 0;
  }
}

@media (max-width: 768px) {
  .movies-card__name {
    max-width: 224px;
  }
}

@media (max-width: 600px) {
  .movies-card__content {
    padding: 14px;
  }
  .movies-card__name {
    font-size: 12px;
  }
  .movies-card__time {
    font-size: 10px;
  }
  .movies-card__btn {
    margin: 14px 0;
  }
}
@media (max-width: 320px) {
  .movies-card {
    height: 272px;
  }
  .movies-card__name {
    max-width: 200px;
  }
  .movies-card__time {
    max-width: 50px;
  }
  .movies-card__photo {
    height: 168px;
  }
}
