.burger-menu {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: none;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .burger-menu {
    display: flex;
  }
}

@media (max-width: 600px) {
  .burger-menu {
    width: 40px;
    height: 43px;
  }
}
