.search-form {
  margin: 0;
  padding: 70px 0 43px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgba(170, 170, 170, 0.2);
  gap: 26px;
}

.search-form__container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.search-form__error {
  padding: 5px;
  content: attr(data-text);
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  width: max-content;
  border-radius: 5px;
  background-color: #302d2d;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  color: #a54646;
  text-align: center;

  transition: all 0.4s ease;
}

.search-form__error_visible {
  visibility: visible;
  opacity: 1;
  top: 115%;
}
.search-form__input {
  margin: 0;
  padding: 25px 125px 25px 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  background: transparent;
  border-radius: 8px;
  border: 1.5px solid rgba(170, 170, 170, 0.2);
  width: 100%;
  height: 72px;
  outline: none;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #8b8b8b;
  caret-color: #8b8b8b;
}

.search-form__input:focus {
  outline: 1px solid #4285f4;
}

.search-form__submit {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 52px;
  outline: none;
  border: none;
  color: #fff;
  border-radius: 5px;
  background: #4285f4;
  cursor: pointer;

  position: absolute;
  top: 10px;
  right: 10px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  transition: opacity 0.3s ease;
}

.search-form__submit_disable {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}

.search-form__submit:hover {
  opacity: 0.8;
}

.search-form__submit:active {
  opacity: 0.5;
}

.search-form__checkbox-label {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  cursor: pointer;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.search-form__checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip-path: inset(0 0 0 0);
}

.search-form__checkbox-span {
  margin: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 36px;
  height: 20px;
}

@media (max-width: 1023px) {
  .search-form {
    padding: 70px 0 51px;
  }
}

@media (max-width: 600px) {
  .search-form {
    padding: 70px 0 63px;
    gap: 30px;
  }
  .search-form__input {
    padding: 20px 95px 20px 12px;
    height: 56px;
    font-size: 16px;
    line-height: 16px;
  }
  .search-form__submit {
    width: 80px;
    height: 44px;
    top: 6px;
    right: 6px;
  }
  .search-form__checkbox-label {
    font-size: 11px;
    gap: 13px;
  }
}
