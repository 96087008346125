.techs {
  margin: 0;
  padding: 100px 70px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: rgba(39, 39, 39, 1);
}

.techs__title {
  margin: 90px 0 0 0;
  padding: 0;
  box-sizing: border-box;
  align-self: center;

  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
  text-align: center;
}

.techs__description {
  margin: 26px 0 100px;
  padding: 0;
  box-sizing: border-box;
  align-self: center;
  max-width: 460px;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.56px;
  text-align: center;
}

.techs__techs-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  align-self: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

@media (max-width: 1023px) {
  .techs {
    padding: 90px 50px;
  }
  .techs__title {
    margin: 80px 0 0;
  }
  .techs__description {
    margin: 22px 0 83px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.48px;
  }
}

@media (max-width: 600px) {
  .techs {
    padding: 70px 18px;
  }
  .techs__title {
    margin: 60px 0 0;
    font-size: 30px;
    letter-spacing: -1.2px;
  }
  .techs__description {
    margin: 24px 0 50px;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.44px;
  }
  .techs__techs-container {
    padding: 0 53px;
    justify-content: space-between;
  }
}
