.not-found {
  margin: 0;
  padding: 246px 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #202020;
  min-height: 100vh;
}

.not-found__title {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.not-found__text {
  margin: 5px 0 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.not-found__home {
  margin: 184px 0 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;

  color: #4285f4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.not-found__home:hover {
  opacity: 0.7;
}

@media (max-width: 1023px) {
  .not-found {
    padding: 408px 0 0;
  }
}

@media (max-width: 500px) {
  .not-found {
    padding: 329px 10px 0;
  }
  .not-found__title {
    font-size: 80px;
  }
  .not-found__text {
    margin: 10px 0 0;
    font-size: 12px;
  }
  .not-found__home {
    margin: 284px 0 0;
    font-size: 12px;
  }
}
