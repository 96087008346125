.logo {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: transparent;

  transition: opacity 0.3s ease;
}

.logo:hover {
  opacity: 0.7;
}
