.subtitle {
  margin: 0;
  padding: 0 0 23px;
  box-sizing: border-box;

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.88px;

  border-bottom: 1px solid rgba(218, 218, 218, 1);
}

@media (max-width: 600px) {
  .subtitle {
    padding: 0 0 28px;
    font-size: 18px;
    letter-spacing: -0.72px;
  }
}
