.project-card {
  margin: 0;
  padding: 0 0 9px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(66, 66, 66, 1);
}

.project-card:last-of-type {
  border: none;
  padding: 0;
}
.project-card__name {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -1.2px;
}

.project-card__link {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;

  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1.2px;

  transition: opacity 0.3s ease-in;
}

.project-card__link:hover {
  opacity: 0.7;
}

@media (max-width: 1023px) {
  .project-card__name {
    font-size: 28px;
    letter-spacing: -1.12px;
  }
  .project-card__link {
    font-size: 28px;
    letter-spacing: -1.12px;
  }
}

@media (max-width: 600px) {
  .project-card__name {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
  .project-card__link {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.72px;
  }
}
