.app {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  min-height: 100vh;

  font-family: "Inter", "Arial", "Helvetica", sans-serif;
  font-style: normal;
  line-height: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
}
