.about-me {
  margin: 0;
  padding: 110px 70px 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #202020;
}

.about-me__content {
  margin: 66px 0 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 49px;
}

.about-me__info {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.about-me__title {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: -2px;
}

.about-me__subtitle {
  margin: 18px 0 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.about-me__description {
  margin: 26px 0 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.about-me__link {
  margin: 99px 0 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;

  text-decoration: none;

  transition: opacity 0.3s ease-in;
}

.about-me__link:hover {
  opacity: 0.7;
}

.about-me__wrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 270px;
  height: 327px;
  overflow: hidden;
  border-radius: 10px;
  flex-shrink: 0;
}

.about-me__img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1023px) {
  .about-me {
    padding: 90px 50px;
  }
  .about-me__info {
    max-width: 364px;
  }
  .about-me__title {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1.6px;
  }
  .about-me__subtitle {
    margin: 16px 0 0;
    font-size: 12px;
    line-height: 18px;
  }
  .about-me__description {
    margin: 20px 0 0;
    font-size: 12px;
    line-height: 18px;
  }
  .about-me__link {
    margin: 87px 0 0;
  }
  .about-me__wrapper {
    width: 255px;
    height: 307px;
  }
}

@media (max-width: 600px) {
  .about-me {
    padding: 70px 14px;
  }
  .about-me__content {
    margin: 60px 0 0;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .about-me__info {
    gap: 20px;
  }
  .about-me__title {
    font-size: 30px;
    letter-spacing: -1.2px;
  }
  .about-me__subtitle {
    margin: 0;
    font-size: 11px;
    line-height: 16px;
  }
  .about-me__description {
    margin: 0;
    font-size: 11px;
    line-height: 16px;
  }
  .about-me__link {
    margin: 40px 0 0;
  }
  .about-me__wrapper {
    width: 292px;
    height: 352px;
  }
}
