.portfolio {
  margin: 0;
  padding: 0 70px 115px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #202020;
}

.portfolio__title {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: rgba(139, 139, 139, 1);
}

.portfolio__list {
  margin: 50px 0 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1023px) {
  .portfolio {
    padding: 0 50px 80px;
  }
}

@media (max-width: 600px) {
  .portfolio {
    padding: 0 14px 70px;
  }
  .portfolio__title {
    font-size: 14px;
    line-height: 20px;
  }
  .portfolio__list {
    margin: 40px 0 0;
  }
}
