.about-project {
  margin: 0;
  padding: 110px 70px;
  box-sizing: border-box;
  background-color: rgba(32, 32, 32, 1);
}

.about-project__stages {
  margin: 0;
  padding: 70px 0 110px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.about-project__stage {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 26px;
}

.about-project__stage-title {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.8px;
}

.about-project__stage-description {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.about-project__stages-grid {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(228px, 1fr) 4fr;
  grid-template-rows: auto;
  justify-items: center;
  row-gap: 14px;
}

.about-project__period {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: stretch;
  min-height: 36px;

  background: #303030;
  color: rgba(255, 255, 255, 1);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about-project__period:first-of-type {
  background: #3ddc84;
  color: #000;
}

.about-project__sphere {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  color: rgba(139, 139, 139, 1);
}

@media (max-width: 1023px) {
  .about-project {
    padding: 90px 50px;
  }
  .about-project__stages {
    padding: 70px 0 93px;
    gap: 30px;
  }
  .about-project__stage {
    gap: 22px;
  }
  .about-project__stage-description {
    font-size: 12px;
    line-height: 18px;
  }
  .about-project__stages-grid {
    grid-template-columns: minmax(140px, 1fr) 4fr;
  }
}

@media (max-width: 600px) {
  .about-project {
    padding: 70px 18px 69px;
  }
  .about-project__stages {
    padding: 60px 0;
    flex-direction: column;
    justify-content: flex-start;
    gap: 56px;
  }
  .about-project__stage {
    gap: 16px;
  }
  .about-project__stage-title {
    font-size: 18px;
    letter-spacing: -0.72px;
  }
  .about-project__stage-description {
    font-size: 11px;
    line-height: 16px;
  }
  .about-project__stages-grid {
    grid-template-columns: minmax(100px, 1fr) 4fr;
  }
  .about-project__period {
    min-height: 35px;
    font-size: 11px;
  }
  .about-project__sphere {
    font-size: 11px;
  }
}
